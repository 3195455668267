.button {
  background-color: $color-blue;
  border: 3px solid $color-blue;
  border-radius: 6px;
  color: $color-white;
  display: inline-block;
  font-family: $font-family-sans;
  font-size: 20px;
  font-weight: $font-weight-bold;
  letter-spacing: 0.25px;
  line-height: rhythm(3);
  padding: 13px 37px;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;

  &:visited {
    color: $color-white;
  }
  
  &:hover {
    background-color: $color-blue-hover;
    border-color: $color-blue-hover;
    color: $color-white;
  }
  
  &:focus {
    background-color: $color-white;
    color: $color-blue;
    text-decoration: underline;
  }
  
  &:active {
    background-color: $color-blue-active;
    border-color: $color-blue-active;
    color: $color-white;
    text-decoration: none;
  }
}

.button--round {
  align-items: center;
  background-color: $color-blue;
  border-radius: 50%;
  display: flex;
  height: rhythm(9);
  justify-content: center;
  margin: 0 20px;
  padding: 20px;
  width: rhythm(9);
}

.button__icon--focus {
  display: none;

  .button:focus & {
    display: block;
  }
}

.button__icon--default {
  .button:focus & {
    display: none;
  }
}