.accordion {
  @include container();

  @include breakpoint ($from: medium) {
    column-gap: rhythm(2);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  @include breakpoint ($from: large) {
    column-gap: rhythm(3);
  }
}

.accordion__item {
  border-top: 1px solid $color-blue-grey;
  padding: rhythm(3) 0; 
  
  &:last-of-type {
    border-bottom: 1px solid $color-blue-grey;
  }

  @include breakpoint ($from: medium) {
    column-gap: rhythm(2);
    display: grid;
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
    padding: rhythm(4) 0;
  }

  @include breakpoint ($from: large) {
    column-gap: rhythm(3);
    grid-column: 3/11;
    grid-template-columns: repeat(8, 1fr);
  }
}

.accordion__item-title {
  cursor: pointer;
  display: flex;

  @include breakpoint ($from: medium) {
    column-gap: rhythm(2);
    grid-template-columns: repeat(10, 1fr);
    display: grid;
    grid-column: 1/11;
    margin: 0;
  }

  @include breakpoint ($from: large) {
    column-gap: rhythm(3);
    grid-column: 1/9;
    grid-template-columns: repeat(8, 1fr);
  }
}

.accordion__item-title-icon {
  margin-right: 18px;

  @include breakpoint ($from: medium) {
    grid-column: 1/2;
    justify-self: center;
    margin: 0;
  }
}

.accordion__icon--expand {
  height: 11px;
  margin-top: 5px; //to help line icon up with the text
  width: 11px;

  .accordion__item--expanded & {
    display: none;
  }

  @include breakpoint ($from: medium) {
    height: 17px;
    margin-top: 4px;
    width: 17px;
  }
}

.accordion__icon--collapse {
  height: 3px;
  display: none;
  margin-top: 9px; //to help line icon up with the text
  width: 11px; //slightly smaller than in the designs, but this helps prevent text title from jumping after it expands

  .accordion__item--expanded & {
    display: inline-block;
  }

  @include breakpoint ($from: medium) {
    height: 5px;
    margin-top: 10px;
    width: 20px;
  }
}

.accordion__item-title-faq {
  @include breakpoint($from: medium) {
    grid-column: 2/10;
  }

  @include breakpoint($from: large) {
    grid-column: 2/8;
  }
}

.accordion__item-content {
  display: none;
  margin-left: 30px;
  margin-top: rhythm(2);

  .accordion__item--expanded & {
    display: block;
  }

  @include breakpoint ($from: medium) {
    grid-column: 2/10;
    margin-left: 0;
    margin-top: rhythm(4);
  }

  @include breakpoint($from: large) {
    grid-column: 2/8;
  }
}

.accordion__item-content-list {
  margin-top: rhythm(2);
  padding: 0;
  
}