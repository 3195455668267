// Contains all Sass variables.
//
// @see https://sass-lang.com/documentation/variables

// Breakpoints.
$breakpoints: (
  medium: 720px,
  large: 1024px
) !default;

// Colors.
$color-beige: #FFFFF5;
$color-black: #191717;
$color-blue: #4850E8;
$color-blue-active: #2129CA;
$color-blue-hover: #242DE8;
$color-blue-grey: #364C58;
$color-dark-grey: #1B2D38;
$color-pink: #FF608D;
$color-pink-screen: #FF3E6D;
$color-silver: #BCC3C9;
$color-silver-30: rgba($color-silver, 0.3);
$color-turquise: #6FFFEF;
$color-turquise-screen: #5FFFEB;
$color-white: #FFFFFF;

// Fonts.
$font-family-sans: 'Rubik', 'sans-serif';
$font-family-serif: 'serif';

// Font weights.
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Units.
$rhythm: 8px;

// Widths.
$width-page-max: 1200px;
