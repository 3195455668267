// Styles that globally target HTML elements (relatively uncommon) live here.
* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  background: $color-dark-grey;
  color: $color-white;
  font-family: $font-family-sans;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  -webkit-font-smoothing: antialiased; 
}

a {
  color: $color-blue;

  &:visited {
    color: $color-blue;
  }

  &:hover {
    color: $color-blue-hover;
  }

  &:active {
    color: $color-blue-active;
  } 
}