.header {
  background-color: $color-black;
  position: sticky;
  top: 0;
  z-index: 3;
}

.header__main-nav {
  @include container();
  align-items: center;
  display: flex;
  height: rhythm(8);
  justify-content: space-between;
  padding: 14px rhythm(2);

  @include breakpoint($from: medium) {
    height: 99px;
    padding-bottom: rhythm(2);
    padding-top: rhythm(2);
  }

  @include breakpoint($from: large) {
    height: 102px;
    padding-bottom: rhythm(5);
    padding-top: rhythm(5);
  }
}

.header__logo {
  padding: 0;
}

.header__logo-link {
  padding: 0; 
}

.header__logo-link-image {
  height: rhythm(8);
  margin: 0;
  width: auto;

  @include breakpoint($from: medium) {
    height: 99px;
  }

  @include breakpoint($from: large) {
    height: 102px;
  }
}

.header__button {
  background-color: $color-black;
  border: none;
  height: 40px;
  padding: 0;
  width: 40px;

  @include breakpoint($from: medium) {
    display: none;
  }
}

.header__button-icon--open {
  .header__button--opened & {
    display: none;
  }
}

.header__button-icon--close {
  display: none;

  .header__button--opened & {
    display: inline-block;
  }
}

.header__main-nav-list {
  display: none;
  flex-direction: column;
  font-size: 20px;
  font-weight: $font-weight-bold;
  letter-spacing: 2.5px;
  list-style-type: none;
  margin: 0 auto;
  position: fixed;
  top: rhythm(8);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  
  @include breakpoint($from: medium) {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin: 0 rhythm(-2) 0 0;
    padding: 0;
    position: static;
  }

  @include breakpoint($from: large) {
    font-size: 18px;
    margin-right: rhythm(-3.5);
  }
}

.header__main-nav-list--expanded {
  @include breakpoint($until: medium) {
    display: flex;
    background-color: $color-black;
    padding: rhythm(3.5) rhythm(2) 0 rhythm(2);
  }
}

.header__main-nav-item {
  border-bottom: 1px solid $color-blue-grey;
  text-transform: uppercase;

  &:last-of-type {
    border: none;
  }

  @include breakpoint($from: medium) {
    border: none;
    margin-left: rhythm(0.5);
  }
}

.header__main-nav-link {
  text-align: center;
  color: $color-white;
  display: block;
  padding: rhythm(6) 0;
  text-decoration: none;

  &:visited {
    color: $color-white;
  }

  &:hover {
    color: $color-white;
    
    .header__link-text {
      border-bottom: 4px solid $color-turquise;
    }
  }

  @include breakpoint($from: medium) {
    padding: 40px 16px;
  }

  @include breakpoint($from: large) {
    padding: rhythm(5) rhythm(3.5) rhythm(5) rhythm(3.5);
  }
}

//Adds active class to nav link, which applies the underline to current page
.header__main-nav-link--active {
  .header__link-text {
    border-bottom: 4px solid $color-turquise;
  }
}